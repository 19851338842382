import { Timestamp } from "firebase/firestore";

export type NotificationLabelType = "important";

export type NotificationTargetKey =
  | "broadcast"
  | NotificationTargetCompanyKey
  | NotificationTargetShopKey;
export type NotificationTargetCompanyKey = `company:${string}`;
export type NotificationTargetShopKey = `shop:${string}`;

export const createNotificationTargetCompanyKey = (
  companyId: string,
): NotificationTargetCompanyKey => `company:${companyId}`;
export const createNotificationTargetShopKey = (shopId: string): NotificationTargetShopKey =>
  `shop:${shopId}`;
export const isNotificationTargetCompanyKey = (
  key: NotificationTargetKey,
): key is NotificationTargetCompanyKey => key.startsWith("company");
export const isNotificationTargetShopKey = (
  key: NotificationTargetKey,
): key is NotificationTargetShopKey => key.startsWith("shop");

export type NotificationChannelType = "handy" | "cashRegister";

export type NotificationSettings = {
  id: string;
  title: string;
  labels: NotificationLabelType[];
  message: string;
  targets: NotificationTargetKey[];
  channels: NotificationChannelType[];
  availableFrom: Timestamp;
  availableUntil: Timestamp;
  updatedAt: Timestamp;
  published: boolean;
};

export type NotificationStatus = "draft" | "unknown" | "ended" | "started" | "reserved";
export const checkNotificationStatus = ({
  availableFrom,
  availableUntil,
  published,
}: Partial<NotificationSettings>): NotificationStatus => {
  if (!published) return "draft";
  if (!availableFrom || !availableUntil) return "unknown";
  const now = Date.now();
  if (availableUntil.toDate().getTime() <= now) {
    return "ended";
  }
  if (availableFrom.toDate().getTime() <= now) {
    return "started";
  }
  return "reserved";
};
