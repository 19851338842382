import React from "react";
import { Card, Checkbox, Form, Input, Typography } from "antd";
import { NotificationStatus } from "models/notificationSettings";

import { Spacer } from "components/Spacer";

export const NotificationContentFields = ({ status }: { status: NotificationStatus }) => {
  const disabled = status !== "draft" && status !== "reserved" && status !== "started";
  return (
    <Card>
      <Typography.Title level={4}>概要</Typography.Title>
      <Form.Item
        label={
          <>
            タイトル
            <Spacer inline size={16} />
            <Form.Item name="isImportant" valuePropName="checked" noStyle>
              <Checkbox>重要ラベルをつける</Checkbox>
            </Form.Item>
          </>
        }
        name="title"
        required
        rules={[{ required: true, message: "タイトルを入力してください" }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        label="本文"
        name="message"
        required
        rules={[{ required: true, message: "本文を入力してください" }]}
      >
        <Input.TextArea disabled={disabled} placeholder="本文を入力" rows={6} showCount />
      </Form.Item>
    </Card>
  );
};
